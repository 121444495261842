import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DateConverterService {

  constructor() { }

  public convertToUTCDate(value: Date): Date | null {
    if ( value && value !== null) {
      return new Date(Date.UTC(value.getFullYear(), value.getMonth(), value.getDate()));
    }
  }

  public convertToUTCDateTime(value: Date): Date | null {
    if ( value && value !== null) {
      return new Date(Date.UTC(value.getFullYear(), value.getMonth(), value.getDate(),
        value.getHours(), value.getMinutes(), value.getSeconds(), 0));
    }
  }

  public convertToUTCDateTimeMidnight(value: Date): Date | null {
    if (value && value !== null) {
      return new Date(Date.UTC(value.getFullYear(), value.getMonth(), value.getDate(), 23, 59, 59));
    }
  }

  public convertToUTCDateTimeZeroHour(value: Date): Date | null {
    if (value && value !== null) {
      return new Date(Date.UTC(value.getFullYear(), value.getMonth(), value.getDate(), 0, 0, 0));
    }
  }

  public projectUTCDateTimeAsLocalDateTime(value: Date | string): Date | null {
    if (value) {
      return typeof value === 'string' ? new Date(value.replace('Z', '')) :
        (value.getTimezoneOffset() >= 0 ? this.parseDate(new Date(Date.parse(value.toISOString().replace('Z', '')))) : value);
    }
  }

  public projectUTCDateTimeAsLocalDate(value: Date | string): Date | null {
    if (value) {
      return new Date(this.projectUTCDateTimeAsLocalDateTime(value).toDateString());
    }
  }

  public projectUTCDateTimeAsLocalDateTimePreserveTime(value: Date): Date | null {
    if (value) {
      const retVal = new Date(value);
      retVal.setHours(retVal.getHours() + (retVal.getTimezoneOffset() / 60));
      return retVal;
    }
  }

  public convertUTCDateTimeToLocalDateTime(value: Date): Date | null {
    if (value) {
      const retVal = new Date(value.getTime() - value.getTimezoneOffset() * 60 * 1000);
      return retVal;
    }
  }

  public parseDate(strDate: Date): Date | null {
    if (strDate != null) {
      strDate = new Date(strDate.getFullYear(), strDate.getMonth(), strDate.getDate(), 0 , 0 , 0);
    }
    return strDate;
  }
}
