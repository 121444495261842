import { Injectable } from '@angular/core';
import { ApplicationInsights, SeverityLevel } from '@microsoft/applicationinsights-web';
import { AppConstants } from 'src/app/core/appConstant';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AppInsightsService {
  appInsights: ApplicationInsights;

  private _userInfo: any = {
    UserEmail: '',
    UserRole: ''
  };
  get userInfo(): any {
    return this._userInfo;
  }
  set userInfo(value: any) {
    this._userInfo = value;
  }

  constructor() {
    this.appInsights = new ApplicationInsights({ config: {
      instrumentationKey: environment.appInsights.instrumentationKey,
      enableCorsCorrelation: true,
      enableAutoRouteTracking: true
    } });
    this.appInsights.loadAppInsights();
    this.appInsights.trackPageView();
  }

  public logPageView(name: string, url?: string, properties?: any, measurements?: any, duration?: number): void {
    if (environment.production) {
      this.appInsights.trackPageView({name, uri:url, properties:this.addUserData(properties), measurements});
    }
  }

  public logEvent(name: string, properties?: any, measurements?: any): void {
    if (environment.production) {
      this.appInsights.trackEvent({name, measurements}, this.addUserData(properties));
    }
  }

  public logError(
    error: Error,
    properties?: { [key: string]: string },
    measurements?: { [key: string]: number },
    log = environment.production): void {
    if (log) {
      this.appInsights.trackException({ exception: error}, this.addUserData(properties));
    }
  }

  public trackTrace(message: string, properties?: { [name: string]: string }, severityLevel?: SeverityLevel): void {
    if (environment.logging.enableTrace) {
      this.appInsights.trackTrace({message, severityLevel}, this.addUserData(properties));
    }
  }

  public setAuthenticatedUserContext() {
    this.appInsights.setAuthenticatedUserContext(this.userInfo.id, this.userInfo.UserRole.replace(/[\s,;=|]/g, ''));
  }

  private addUserData(properties: any): any {
    this.setAuthenticatedUserContext();
      if (properties) {
        properties.Source = AppConstants.aiSource;
        properties.TimeStampUTC = new Date().toISOString();
      } else {
        properties = {
          Source : AppConstants.aiSource,
          TimeStampUTC : new Date().toISOString()
        };
      }
      return properties;
  }
}
