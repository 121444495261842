import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { AccessRightsRouteGurd } from './core/routegurds/accessrightsroutegurd';
import { EmptyComponent } from './empty/empty.component';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';

const routes: Routes = [
  { path: 'Admin', loadChildren: () => import('./u2-admin/u2-admin.module').then(m => m.U2ADMINModule)},

  { path: 'Admin/CustomerService', loadChildren: () => import('./u2-person/u2-person.module').then(m => m.U2PERSONMODULE)},
  { path: 'Admin', loadChildren: () => import('./u2-person/u2-person.module').then(m => m.U2PERSONMODULE)},

  { path: 'PayRates', loadChildren: () => import('./u2-payrates/u2-payrates.module').then(m => m.U2PayratesModule)},
  { path: 'Admin', loadChildren: () => import('./u2-payrates/u2-payrates.module').then(m => m.U2PayratesModule)},

  { path: 'Payroll', loadChildren: () => import('./u2-payroll/u2-payroll.module').then(m => m.U2PayrollModule)},
  { path: 'Maintenance', loadChildren: () => import('./u2-admin/u2-admin.module').then(m => m.U2ADMINModule)},
  { path: 'Maintenance', loadChildren: () => import('./u2-maintenance/u2-maintenance.module').then(m => m.U2MaintenanceModule)},
  { path: 'Authorizations', loadChildren: () => import('./u2-maintenance/u2-maintenance.module').then(m => m.U2MaintenanceModule)},
  { path: 'Admin', loadChildren: () => import('./u2-maintenance/u2-maintenance.module').then(m => m.U2MaintenanceModule)},
  { path: 'Billing/Inquiry', loadChildren: () => import('./u2-inquiry/u2-inquiry.module').then(m => m.U2InquiryModule)},
  { path: 'Billing/Process', loadChildren: () => import('./u2-process/u2-process.module').then(m => m.U2ProcessModule)},
  { path: 'Transactions', loadChildren: () => import('./u2-admin/u2-admin.module').then(m => m.U2ADMINModule)},
  { path: 'Transactions', loadChildren: () => import('./u2-process/u2-process.module').then(m => m.U2ProcessModule)},
  { path: 'UTEX', loadChildren: () => import('./u2-utex/u2-utex.module').then(m => m.U2UTEXModule)},
  { path: 'SOLOMON', loadChildren: () => import('./u2-solomon/u2-solomon.module').then(m => m.U2SOLOMONModule)},
  { path: 'CaregiverConversion', loadChildren: () =>
  import('./u2-caregiver-conversion-editor/u2-caregiver-conversion.module').then(m => m.U2CaregiverConversionModule)},
  { path: 'FEA', loadChildren: () => import('./u2-fea/u2-fea.module').then(m => m.U2FeaModule)},
  { path: 'Billing/ClaimsSetup', loadChildren: () => import('./u2-claims-setup/u2-claims-setup.module').then(m => m.U2ClaimsSetupModule)},
  { path: 'GL', loadChildren: () => import('./u2-claims-setup/u2-claims-setup.module').then(m => m.U2ClaimsSetupModule)},
  { path: 'Billing', loadChildren: () => import('./u2-claims-setup/u2-claims-setup.module').then(m => m.U2ClaimsSetupModule)},
  { path: '', component: EmptyComponent, pathMatch: 'full', canActivate: [MsalGuard, AccessRightsRouteGurd]},
  { path: 'code', component: EmptyComponent, pathMatch: 'full', canActivate: [MsalGuard, AccessRightsRouteGurd]},
  { path: 'unauthorized', component: UnauthorizedComponent, pathMatch: 'full', canActivate: [MsalGuard, AccessRightsRouteGurd]}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
