import { Action } from '@ngrx/store';
import { CompositeFilterDescriptor } from '@progress/kendo-data-query';
import {
    IStringInt32KeyValuePair,
    IPersonRequestDto,
    AgencyPersonMapping,
    IPersonAgenciesDto,
    PersonAgenciesDto,
    StatusHistory,
    PersonResponseDtoGridResponseBase,
    PersonType,
    Participants,
    DesignatedRepresentativeProfile,
    AgencyAdminProfile,
    ParticipantDesignatedRepresentative,
    Relationships,
    CaregiverDetails,
    CaregiverEmployerList,
    CaregiverProfile,
    ProgramCoordinatorProfile,
    ProgramCoordinatorDto,
    ServiceFacilitatorProfile,
    ServiceFacilitatorCaseLoad,
    EmployerOfRecordResponse,
    CustEmpRelFilterRequest,
    CustEmpRelResponseDtoGridResponseBase
} from 'src/app/core/services/peopleapi/peoplefunctionapi.services';
import { PayRateModel, GridResponseBaseOfEINList } from 'src/app/core/services/u2api.services';

// Declaring an enum
export enum PersonActionTypes {
    SearchClicked = '[Person Search Page] Search Clicked',
    SearchFilterChanged = '[Person Search Page] Search Filter Changed',
    UnloadPersonsTypeSearchCriteria = '[Nav menu bar] Nav menu changed',
    LoadPersons = '[Person Search Page] Load Persons',
    LoadPersonsSuccess = '[Person Search Page] Load Persons Success',
    LoadPersonsFail = '[Person Search Page] Load Persons Fail',
    LoadPersonType = '[Person Search Page] Load Person Type',
    LoadPersonTypeSuccess = '[Person Search Page] Load Person Type Success',
    LoadPersonTypeFail = '[Person Search Page] Load Person Type Fail',
    LoadPersonsAgency = '[Person Agency Mapping Page] Load Persons Agency',
    LoadPersonsAgencySuccess = '[Person Agency Mapping Page] Persons Agency Success',
    LoadPersonsAgencyStatusHistory = '[Person Agency Page] Load Persons Agency Status History',
    LoadPersonsAgencyStatusHistoriesSuccess = '[Person Agency History Page] Persons Agency Status History Success',
    LoadAgencyAdminProfile = '[Person Agency Page] Load Persons Agency Profile',
    LoadAgencyAdminProfileSuccess = '[Person Agency Admin Profile] Persons Agency Status History Success',
    AgencyAdminDetail = '[Person Agency Detail] Persons Agency Detail',
    LoadAgencyAdminAgencies = '[Person Agency Page] Load Agency Admin Agencies',
    LoadAgencyAdminAgenciesSuccess = '[Person Agency Admin Profile] Load Agency Admin Agencies',
    LoadAAPersonsFail = '[Person Search Page] Load Persons Fail',
    LoadSFAgencies = '[Person Service Facilitator Page] Load Service Facilitator Agencies',
    LoadSFAgenciesSuccess = '[Person Service Facilitator Profile] Load Service Facilitator Agencies',
    DesignatedRepresentativeDetail = '[Person DR Detail] Designated Representative Detail',
    LoadPersonsDesignatedRepresentativeSuccess = '[Person Designated Representative Mapping Page]'
    + 'Persons Designated Representative Success',
    LoadPersonsDesignatedRepresentativeStatusHistory = '[Designated Representative Page]'
    + 'Load Persons Designated Representative Status History',
    LoadPersonsDesignatedRepresentativeStatusHistoriesSuccess = '[Person Designated Representative History Page]'
    + 'Persons Designated Representative Status History Success',
    LoadPersonsDRProfile = '[Designated Representative Profile Page]'
    + 'Load Persons Designated Representative Profile',
    LoadDRProfileSuccess = '[Person Designated Representative Profile Page]'
    + 'Persons Designated Representative Profile',
    LoadDRProfileFlush = '[Person Designated Representative Flush Profile]'
    + 'Persons Designated Representative Flush Profile',
    LoadDRParticipant = '[Designated Representative Participant Page]'
    + 'Load Persons Designated Representative Participant',
    LoadDRParticipantSuccess = '[Person Designated Representative Participant Page]'
    + 'Persons Designated Representative Participant',
    LoadDRPersonsFail = '[Person DR] Load DR Persons Fail',
    LoadParticipantProfile = '[Person Profile Page] Load Persons Participant Profile',
    LoadParticipantProfileSuccess = '[Person participant Profile] Persons Participant Profile Success',
    LoadParticipantCaregiverPayRates = '[Participant Caregiver Rate Page] Load Participant Caregiver PayRates',
    LoadParticipantCaregiverPayRatesSuccess = '[Participant Caregiver Rate Page] Load Participant Caregiver PayRates Success',
    LoadParticipantCaregiverPayRatesFail = '[Participant Caregiver Rate Page] Load Participant Caregiver PayRates Fail',
    LoadParticipantDrRelation = '[Participant Relation Page] Load Participant DesignatedRep Relation',
    LoadParticipantDrRelationFail = '[Participant Relation Page] Load Participant DesignatedRep RelationFail',
    LoadParticipantDrRelationSuccess = '[Participant Relation Page] Load Participant DesignatedRep Relation Success',
    LoadParticipantCaregiverRelation = '[Participant Relation Page] Load Participant Caregiver Relation',
    LoadParticipantCaregiverRelationSuccess = '[Participant Relation Page] Load Participant Caregiver Relation Success',
    LoadParticipantCaregiverRelationFail = '[Participant Relation Page] Load Participant Caregiver Relation Fail',
    LoadParticipantEmployerOfRecordRelation = '[Participant Relation Page] Load Participant EmployerOfRecord Relation',
    LoadParticipantEmployerOfRecordRelationSuccess = '[Participant Relation Page] Load Participant EmployerOfRecord Relation Success',
    LoadParticipantEmployerOfRecordRelationFail = '[Participant Relation Page] Load Participant EmployerOfRecord Relation Fail',
    LoadParticipant = '[Participant] Load Participants',
    LoadParticipantSuccess = '[Participant] Load Participants Success',
    LoadParticipantFail = '[Participant] Load Participants Fail',
    LoadEmployerOfRecord = '[EmployerOfRecord] Load Employer Of Record',
    LoadEmployerOfRecordSuccess = '[EmployerOfRecord] Load Employer Of Record Success',
    LoadEmployerOfRecordFail = '[EmployerOfRecord] Load Employer Of Record Fail',
    LoadEmployerOfRecordFlush = '[EmployerOfRecord Flush] Employer Of Record Flush',
    LoadCaregiverSuccess = '[Caregiver] Load Caregiver Success',
    LoadCaregiverFail = '[Caregiver] Load Caregiver Fail',
    LoadCaregiver = '[Caregiver] Load Caregiver',
    LoadCaregiverProfileSuccess = '[Caregiver] Load Caregiver Profile Success',
    LoadCaregiverProfileFail = '[Caregiver] Load Caregiver Profile Fail',
    LoadCaregiverProfile = '[Caregiver] Load Caregiver Profile',
    LoadCaseManagerProfile = '[CaseManager] Load CaseManager Profile',
    LoadEmployerOfRecordParticipant = '[EmployerOfRecord Participant Page]'
    + 'Load Persons Employer Of Record Participant',
    LoadEmployerOfRecordParticipantSuccess = '[Person EmployerOfRecord Participant Page]'
    + 'Persons Employer Of Record Participant',
    LoadEmployerOfRecordCaregiver = '[EmployerOfRecord Caregiver Page]'
    + 'Load Persons Employer Of Record Caregiver',
    LoadEmployerOfRecordCaregiverSuccess = '[Person EmployerOfRecord Caregiver Page]'
    + 'Persons Employer Of Record Caregiver',
    LoadEmployerOfRecordPersonsFail = '[Person EmployerOfRecord] Load Employer Of Record Persons Fail',
    // SF Profile
    LoadPersonsSFProfile = '[Service Facilitator Profile Page]'
    + 'Load Persons Service Facilitator Profile',
    LoadSFProfileSuccess = '[Person Service Facilitator Profile Page]'
    + 'PersonsService Facilitator Profile',
    LoadSFPersonsFail = '[Person SF] Load SF Persons Fail',
    LoadSFProfileFlush = '[Person Service Facilitator Flush Profile]'
    + 'Persons Service Facilitator Flush Profile',
    LoadAgencies = '[Person SF] Load SF Agencies',
    LoadAgenciesSuccess = '[Person SF Profile] Load sF Agencies',
    LoadSFAgencyFlush = '[Person Service Facilitator Flush agency]'
    + 'Persons Service Facilitator Flush agency',
    LoadAAAgencyFlush = '[Person Service Facilitator Flush agency]'
    + 'Persons AA Flush agency',
    LoadCaregiverParticipantRelation = '[Caregiver] Load Caregiver Participant Relation',
    LoadCaregiverParticipantRelationSuccess = '[Caregiver] Load Caregiver Participant Relation Success',
    LoadCaregiverParticipantRelationFail = '[Caregiver] Load Caregiver Participant Relation Fail',
    LoadCaregiverEmployerOfRecordRelation = '[Caregiver] Load Caregiver EmployerOfRecord Relation',
    LoadCaregiverEmployerOfRecordRelationSuccess = '[Caregiver] Load Caregiver EmployerOfRecord Relation Success',
    LoadCaregiverEmployerOfRecordRelationFail = '[Caregiver] Load Caregiver EmployerOfRecord Relation Fail',
    LoadSFCaseLoads = '[Person SF] Load SF Agencies',
    LoadSFCaseLoadsSuccess = '[Person SF caseload] Load sF caseload',
    LoadProgramCoordinatorSuccess = '[ProgramCoordinator] Load Program Coordinator Success',
    LoadProgramCoordinatorFail = '[ProgramCoordinator] Load Program Coordinator Fail',
    LoadProgramCoordinator = '[ProgramCoordinator] Load Program Coordinator',
    LoadProgramCoordinatorProfileSuccess = '[ProgramCoordinator] Load Program Coordinator Profile Success',
    LoadProgramCoordinatorProfileFail = '[ProgramCoordinator] Load Program Coordinator Profile Fail',
    LoadProgramCoordinatorProfile = '[ProgramCoordinator] Load Program Coordinator Profile',
}
// Creating a class for Action

export class UnloadPersonsTypeSearchCriteria implements Action {
    readonly type = PersonActionTypes.UnloadPersonsTypeSearchCriteria;
    constructor() { }
}

export class SearchClicked implements Action {
    readonly type = PersonActionTypes.SearchClicked;
    constructor(public payload: IPersonRequestDto) { }
}

export class SearchFilterChanged implements Action {
    readonly type = PersonActionTypes.SearchFilterChanged;
    constructor(public payload: CompositeFilterDescriptor) { }
}

export class LoadPersons implements Action {
    readonly type = PersonActionTypes.LoadPersons;
    constructor(public payload: IPersonRequestDto) { }
}

export class LoadPersonsSuccess implements Action {
    readonly type = PersonActionTypes.LoadPersonsSuccess;
    constructor(public payload: PersonResponseDtoGridResponseBase) { }
}

export class LoadPersonsFail implements Action {
    readonly type = PersonActionTypes.LoadPersonsFail;
    constructor(public payload: string) { }
}

// get Agency Admin by Person

export class AgencyAdminDetail implements Action {
    readonly type = PersonActionTypes.AgencyAdminDetail;
    constructor(public payload: IPersonAgenciesDto) { }
}

export class GetAgencyPersonMapping implements Action {
    readonly type = PersonActionTypes.LoadPersonsAgency;
    constructor(public payload: string) { }
}

// get Agency Admin by Person

export class LoadPersonAgencySuccess implements Action {
    readonly type = PersonActionTypes.LoadPersonsAgencySuccess;
    constructor(public payload: PersonAgenciesDto) { }
}

// get status History by Person
export class LoadPersonType implements Action {
    readonly type = PersonActionTypes.LoadPersonType;
    constructor() { }
}

export class LoadPersonTypeSuccess implements Action {
    readonly type = PersonActionTypes.LoadPersonTypeSuccess;
    constructor(public payload: Array<IStringInt32KeyValuePair>) { }
}

export class LoadPersonTypeFail implements Action {
    readonly type = PersonActionTypes.LoadPersonTypeFail;
    constructor(public payload: string) { }
}

export class LoadParticipantCaregiverPayRates implements Action {
    readonly type = PersonActionTypes.LoadParticipantCaregiverPayRates;
    constructor(public empId: number, public participantId: number) { }
}

export class LoadParticipantCaregiverPayRatesSuccess implements Action {
    readonly type = PersonActionTypes.LoadParticipantCaregiverPayRatesSuccess;
    constructor(public payload: PayRateModel[]) { }
}

export class LoadParticipantCaregiverPayRatesFail implements Action {
    readonly type = PersonActionTypes.LoadParticipantCaregiverPayRatesFail;
    constructor(public payload: string) { }
}


export class GetAgencyPersonStatusHistory implements Action {
    readonly type = PersonActionTypes.LoadPersonsAgencyStatusHistory;
    constructor(public payload: number) { }
}
export class LoadParticipantDrRelation implements Action {
    readonly type = PersonActionTypes.LoadParticipantDrRelation;
    constructor(public participantId: number) { }
}

export class LoadParticipantDrRelationSuccess implements Action {
    readonly type = PersonActionTypes.LoadParticipantDrRelationSuccess;
    constructor(public payload: ParticipantDesignatedRepresentative[]) { }
}


export class LoadParticipantDrRelationFail implements Action {
    readonly type = PersonActionTypes.LoadParticipantDrRelationFail;
    constructor(public payload: string) { }
}

export class LoadPersonAgencyStatusHistorySuccess implements Action {
    readonly type = PersonActionTypes.LoadPersonsAgencyStatusHistoriesSuccess;
    constructor(public payload: Array<StatusHistory>) { }
}

// get status History by Person
export class GetAgencyAdminProfile implements Action {
    readonly type = PersonActionTypes.LoadAgencyAdminProfile;
    constructor(public payload: string) { }
}

export class LoadAAProfileSuccess implements Action {
    readonly type = PersonActionTypes.LoadAgencyAdminProfileSuccess;
    constructor(public payload: AgencyAdminProfile) { }
}

export class LoadAAPersonFail implements Action {
    readonly type = PersonActionTypes.LoadAAPersonsFail;
    constructor(public payload: string) { }
}
export class LoadParticipant implements Action {
    readonly type = PersonActionTypes.LoadParticipant;
    constructor(public payload: string) { }
}

export class LoadEmployerOfRecord implements Action {
    readonly type = PersonActionTypes.LoadEmployerOfRecord;
    constructor(public payload: string) { }
}

export class EmployerOfRecordFlush implements Action {
    readonly type = PersonActionTypes.LoadEmployerOfRecordFlush;
    constructor(public personId: string) { }
}

export class LoadEmployerOfRecordSuccess implements Action {
    readonly type = PersonActionTypes.LoadEmployerOfRecordSuccess;
    constructor(public payload: EmployerOfRecordResponse) { }
}

export class LoadEmployerOfRecordFail implements Action {
    readonly type = PersonActionTypes.LoadEmployerOfRecordFail;
    constructor(public payload: string) { }
}

export class GetAgencyAdminAgencies implements Action {
    readonly type = PersonActionTypes.LoadAgencyAdminAgencies;
    constructor(public peopleId: string) { }
}

export class LoadAAAgencySuccess implements Action {
    readonly type = PersonActionTypes.LoadAgencyAdminAgenciesSuccess;
    constructor(public payload: Array<AgencyPersonMapping>, public personId: string) { }
}

// SF
export class GetSFSuccess implements Action {
    readonly type = PersonActionTypes.LoadSFAgenciesSuccess;
    constructor(public payload: PersonAgenciesDto) { }
}

export class SFDetail implements Action {
    readonly type = PersonActionTypes.LoadSFAgencies;
    constructor(public payload: string) { }
}

// DR Type
export class DesignatedRepresentativeDetail implements Action {
    readonly type = PersonActionTypes.LoadPersonsDesignatedRepresentativeSuccess;
    constructor(public payload: PersonType) { }
}

export class GetDesignatedRepresentative implements Action {
    readonly type = PersonActionTypes.DesignatedRepresentativeDetail;
    constructor(public payload: string) { }
}

// get status History by DR Person

export class GetDesignatedRepresentativeStatusHistory implements Action {
    readonly type = PersonActionTypes.LoadPersonsDesignatedRepresentativeStatusHistory;
    constructor(public payload: number) { }
}

export class LoadDesignatedRepresentativeStatusHistorySuccess implements Action {
    readonly type = PersonActionTypes.LoadPersonsDesignatedRepresentativeStatusHistoriesSuccess;
    constructor(public payload: Array<StatusHistory>) { }
}

export class LoadCaregiver implements Action {
    readonly type = PersonActionTypes.LoadCaregiver;
    constructor(public payload: string) { }
}

export class LoadParticipantCaregiverRelation implements Action {
    readonly type = PersonActionTypes.LoadParticipantCaregiverRelation;
    // eslint-disable-next-line max-len
    constructor(public payload: CustEmpRelFilterRequest) { }
}

export class LoadParticipantCaregiverRelationSuccess implements Action {
    readonly type = PersonActionTypes.LoadParticipantCaregiverRelationSuccess;

    constructor(public payload: CustEmpRelResponseDtoGridResponseBase) { }
}

export class LoadParticipantCaregiverRelationFail implements Action {
    readonly type = PersonActionTypes.LoadParticipantCaregiverRelationFail;
    constructor(public payload: string) { }
}

export class LoadParticipantEmployerOfRecordRelation implements Action {
    readonly type = PersonActionTypes.LoadParticipantEmployerOfRecordRelation;
    constructor(public participantId: number) { }
}

export class LoadParticipantEmployerOfRecordRelationSuccess implements Action {
    readonly type = PersonActionTypes.LoadParticipantEmployerOfRecordRelationSuccess;
    constructor(public payload: GridResponseBaseOfEINList) { }
}

export class LoadParticipantEmployerOfRecordRelationFail implements Action {
    readonly type = PersonActionTypes.LoadParticipantEmployerOfRecordRelationFail;
    constructor(public payload: string) { }
}

export class LoadParticipantSuccess implements Action {
    readonly type = PersonActionTypes.LoadParticipantSuccess;
    constructor(public payload: Participants) { }
}

export class LoadParticipantFail implements Action {
    readonly type = PersonActionTypes.LoadParticipantFail;
    constructor(public payload: string) { }
}

export class GetDRProfile implements Action {
    readonly type = PersonActionTypes.LoadPersonsDRProfile;

    constructor(public payload: string) { }
}

export class LoadCaregiverSuccess implements Action {
    readonly type = PersonActionTypes.LoadCaregiverSuccess;
    constructor(public payload: CaregiverDetails) { }
}

export class LoadCaregiverFail implements Action {
    readonly type = PersonActionTypes.LoadCaregiverFail;
    constructor(public payload: string) { }
}

export class LoadCaregiverParticipantRelation implements Action {
    readonly type = PersonActionTypes.LoadCaregiverParticipantRelation;
    // eslint-disable-next-line max-len
    constructor(public payload?: CustEmpRelFilterRequest) { }
}

export class LoadCaregiverParticipantRelationSuccess implements Action {
    readonly type = PersonActionTypes.LoadCaregiverParticipantRelationSuccess;
    constructor(public payload: CustEmpRelResponseDtoGridResponseBase) { }
}

export class LoadCaregiverParticipantRelationFail implements Action {
    readonly type = PersonActionTypes.LoadCaregiverParticipantRelationFail;
    constructor(public payload: string) { }
}

export class LoadCaregiverEmployerOfRecordRelation implements Action {
    readonly type = PersonActionTypes.LoadCaregiverEmployerOfRecordRelation;
    constructor(public caregiverId: number) { }
}

export class LoadCaregiverEmployerOfRecordRelationSuccess implements Action {
    readonly type = PersonActionTypes.LoadCaregiverEmployerOfRecordRelationSuccess;
    constructor(public payload: CaregiverEmployerList[]) { }
}

export class LoadCaregiverEmployerOfRecordRelationFail implements Action {
    readonly type = PersonActionTypes.LoadCaregiverEmployerOfRecordRelationFail;
    constructor(public payload: string) { }
}

export class LoadCaregiverProfile implements Action {
    readonly type = PersonActionTypes.LoadCaregiverProfile;
    constructor(public payload: string) { }
}

export class LoadCaregiverProfileSuccess implements Action {
    readonly type = PersonActionTypes.LoadCaregiverProfileSuccess;
    constructor(public payload: CaregiverProfile) { }
}

export class LoadCaregiverProfileFail implements Action {
    readonly type = PersonActionTypes.LoadCaregiverProfileFail;
    constructor(public payload: string) { }
}


export class LoadProgramCoordinatorProfile implements Action {
    readonly type = PersonActionTypes.LoadProgramCoordinatorProfile;
    constructor(public payload: string) { }
}

export class LoadProgramCoordinatorProfileSuccess implements Action {
    readonly type = PersonActionTypes.LoadProgramCoordinatorProfileSuccess;
    constructor(public payload: ProgramCoordinatorProfile) { }
}

export class LoadProgramCoordinatorProfileFail implements Action {
    readonly type = PersonActionTypes.LoadProgramCoordinatorProfileFail;
    constructor(public payload: string) { }
}

export class LoadCaseManagerProfile implements Action {
    readonly type = PersonActionTypes.LoadCaregiverProfile;
    constructor(public payload: string) { }
}

export class LoadProgramCoordinator implements Action {
    readonly type = PersonActionTypes.LoadProgramCoordinator;
    constructor(public payload: string) { }
}

export class LoadProgramCoordinatorSuccess implements Action {
    readonly type = PersonActionTypes.LoadProgramCoordinatorSuccess;
    constructor(public payload: ProgramCoordinatorDto) { }
}

export class LoadProgramCoordinatorFail implements Action {
    readonly type = PersonActionTypes.LoadProgramCoordinatorFail;
    constructor(public payload: string) { }
}

export class LoadDRProfileSuccess implements Action {
    readonly type = PersonActionTypes.LoadDRProfileSuccess;

    constructor(public payload: DesignatedRepresentativeProfile) { }
}

export class DRProfileFlush implements Action {
    readonly type = PersonActionTypes.LoadDRProfileFlush;

    constructor(public personId: string) { }
}

export class GetDRParticipant implements Action {
    readonly type = PersonActionTypes.LoadDRParticipant;

    constructor(public payload: string) { }
}

export class LoadDRParticipantSuccess implements Action {
    readonly type = PersonActionTypes.LoadDRParticipantSuccess;

    constructor(public payload: Array<ParticipantDesignatedRepresentative>, public personId: string) { }
}

export class LoadDRPersonFail implements Action {
    readonly type = PersonActionTypes.LoadDRPersonsFail;

    constructor(public payload: string) { }
}


// SF Profile

export class GetSFProfile implements Action {
    readonly type = PersonActionTypes.LoadPersonsSFProfile;

    constructor(public payload: string) { }
}

export class LoadSFProfileSuccess implements Action {
    readonly type = PersonActionTypes.LoadSFProfileSuccess;

    constructor(public payload: ServiceFacilitatorProfile) { }
}

export class SFProfileFlush implements Action {
    readonly type = PersonActionTypes.LoadSFProfileFlush;

    constructor(public personId: string) { }
}

export class LoadSFPersonFail implements Action {
    readonly type = PersonActionTypes.LoadSFPersonsFail;

    constructor(public payload: string) { }
}

export class LoadEmployerOfRecordParticipant implements Action {
    readonly type = PersonActionTypes.LoadEmployerOfRecordParticipant;

    constructor(public payload: string) { }
}

export class LoadEmployerOfRecordParticipantSuccess implements Action {
    readonly type = PersonActionTypes.LoadEmployerOfRecordParticipantSuccess;

    constructor(public payload: Array<Relationships>, public personId: string) { }
}
export class GetSFAgencies implements Action {
    readonly type = PersonActionTypes.LoadAgencies;
    constructor(public peopleId: string) { }
}

export class LoadEmployerOfRecordCaregiver implements Action {
    readonly type = PersonActionTypes.LoadEmployerOfRecordCaregiver;

    constructor(public payload: string) { }
}

export class LoadEmployerOfRecordCaregiverSuccess implements Action {
    readonly type = PersonActionTypes.LoadEmployerOfRecordCaregiverSuccess;

    constructor(public payload: Array<Relationships>, public personId: string) { }
}

export class LoadEmployerOfRecordPersonFail implements Action {
    readonly type = PersonActionTypes.LoadEmployerOfRecordPersonsFail;

    constructor(public payload: string) { }
}



export class LoadSFAgencySuccess implements Action {
    readonly type = PersonActionTypes.LoadAgenciesSuccess;
    constructor(public payload: Array<AgencyPersonMapping>, public personId: string) { }
}

export class LoadSFAgencyFlush implements Action {
    readonly type = PersonActionTypes.LoadSFAgencyFlush;
    constructor(public payload: string) { }
}

export class LoadAAAgencyFlush implements Action {
    readonly type = PersonActionTypes.LoadAAAgencyFlush;
    constructor(public payload: string) { }
}

export class GetSFCaseLoads implements Action {
    readonly type = PersonActionTypes.LoadSFCaseLoads;
    constructor(public peopleId: string) { }
}

export class LoadSFCaseLoadsSuccess implements Action {
    readonly type = PersonActionTypes.LoadSFCaseLoadsSuccess;
    constructor(public payload: Array<ServiceFacilitatorCaseLoad>, public personId: string) { }
}

// Union of all actions. | can be read as Or
export type PersonActions =
    SearchClicked |
    SearchFilterChanged |
    UnloadPersonsTypeSearchCriteria |
    LoadPersons |
    LoadPersonsSuccess |
    LoadPersonsFail |
    LoadPersonType |
    LoadPersonTypeSuccess |
    LoadPersonTypeFail |
    LoadPersonAgencySuccess |
    GetAgencyPersonMapping |
    LoadPersonAgencyStatusHistorySuccess |
    GetAgencyPersonStatusHistory |
    LoadAAProfileSuccess |
    GetAgencyAdminProfile |
    GetAgencyAdminAgencies | LoadAAAgencyFlush |
    LoadAAAgencySuccess |
    SFDetail |
    GetSFSuccess |
    LoadDesignatedRepresentativeStatusHistorySuccess |
    DesignatedRepresentativeDetail |
    LoadAAPersonFail |
    LoadPersonTypeFail |
    LoadParticipantCaregiverPayRates |
    LoadParticipantCaregiverPayRatesSuccess |
    LoadParticipantCaregiverPayRatesFail |
    LoadParticipantDrRelation |
    LoadParticipantDrRelationSuccess |
    LoadParticipantDrRelationFail |
    LoadParticipantEmployerOfRecordRelation |
    LoadParticipantEmployerOfRecordRelationSuccess |
    LoadParticipantEmployerOfRecordRelationFail |
    LoadParticipantCaregiverRelation |
    LoadParticipantCaregiverRelationSuccess |
    LoadParticipantCaregiverRelationFail |
    LoadParticipant |
    LoadParticipantSuccess |
    LoadParticipantFail |
    DesignatedRepresentativeDetail |
    LoadAAPersonFail |
    LoadPersonTypeFail |
    LoadParticipantCaregiverPayRates |
    LoadParticipantCaregiverPayRatesSuccess |
    LoadParticipantCaregiverPayRatesFail |
    LoadParticipantDrRelation |
    LoadParticipantDrRelationSuccess |
    LoadParticipantDrRelationFail |
    LoadParticipantEmployerOfRecordRelation |
    LoadParticipantEmployerOfRecordRelationSuccess |
    LoadParticipantEmployerOfRecordRelationFail |
    LoadParticipantCaregiverRelation |
    LoadParticipantCaregiverRelationSuccess |
    LoadParticipantCaregiverRelationFail |
    LoadParticipant |
    LoadParticipantSuccess |
    LoadParticipantFail |
    LoadCaregiver |
    LoadCaregiverSuccess |
    LoadCaregiverFail |
    LoadCaregiverProfile |
    LoadCaregiverProfileSuccess |
    LoadCaregiverProfileFail |
    GetDRProfile |
    LoadDRProfileSuccess |
    LoadDRPersonFail |
    GetDRParticipant |
    LoadDRParticipantSuccess |
    DRProfileFlush |
    LoadEmployerOfRecordParticipant |
    LoadEmployerOfRecordParticipantSuccess |
    LoadEmployerOfRecordCaregiver |
    LoadEmployerOfRecordCaregiverSuccess |
    LoadDRPersonFail |
    LoadSFPersonFail |
    GetSFAgencies |
    LoadSFAgencySuccess |
    LoadSFAgencyFlush |
    LoadCaregiverParticipantRelation |
    LoadCaregiverParticipantRelationSuccess |
    LoadCaregiverParticipantRelationFail |
    LoadCaregiverEmployerOfRecordRelation |
    LoadCaregiverEmployerOfRecordRelationSuccess |
    LoadCaregiverEmployerOfRecordRelationFail |
    GetSFProfile | LoadSFProfileSuccess | SFProfileFlush | LoadSFPersonFail |
    GetSFAgencies | LoadSFAgencySuccess | LoadSFAgencyFlush |
    GetSFCaseLoads | LoadSFCaseLoadsSuccess |
    LoadEmployerOfRecord | LoadEmployerOfRecordSuccess | LoadEmployerOfRecordFail | EmployerOfRecordFlush |
    LoadProgramCoordinator |
    LoadProgramCoordinatorSuccess |
    LoadProgramCoordinatorFail |
    LoadProgramCoordinatorProfile |
    LoadProgramCoordinatorProfileSuccess |
    LoadProgramCoordinatorProfileFail
    ;
