import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GridDataResult, PageChangeEvent, PagerSettings, SelectableSettings } from '@progress/kendo-angular-grid';
import { CompositeFilterDescriptor, SortDescriptor } from '@progress/kendo-data-query';
import { ToastrService } from 'ngx-toastr';
import { ICompany } from 'src/app/core/models/company';
import { ValidationErrorExpressionConstants } from 'src/app/shared/constants/error-expression-constants';
import { CommonFunctions } from 'src/app/shared/utilities/common-functions';
import { DomSanitization } from 'src/app/shared/utilities/dom-sanitization';
import { KendoMasterGridProperties, KendoModalGridProperties } from 'src/app/shared/utilities/kendo-properties';
import { CompanyModel, GridResponseBaseOfCompanyModel, UserService } from './../../core/services/u2api.services';

@Component({
  selector: 'morecompanies-lookup',
  templateUrl: './morecompanies-lookup.component.html',
  styleUrls: ['./morecompanies-lookup.component.scss']
})
export class MorecompaniesLookupComponent implements OnInit {

  public moreCompaniesGridView: GridDataResult;
  public pageSize = KendoModalGridProperties.pageSize;
  public pageNumber: number;
  public skip: number;
  public loading: boolean;
  public sortColumn: string;
  public sortDirection: string;
  public filterColumn: string;
  public filterValue: string;
  public filter: CompositeFilterDescriptor;
  public sort: SortDescriptor[] = [];
  public selectedItemId: number[] = [];
  public selectableSettings: SelectableSettings = {
    checkboxOnly: KendoModalGridProperties.toSelectcheckboxOnly,
    mode: KendoModalGridProperties.selectionMode,
  };
  public pageable: PagerSettings = {
    buttonCount: KendoMasterGridProperties.buttonCount,
    info: KendoMasterGridProperties.info,
    type: KendoMasterGridProperties.type,
    pageSizes: KendoMasterGridProperties.pageSizes,
    previousNext: KendoMasterGridProperties.previousNext,
  };
  @Input()
  existingCompanies = '';
  @Output()
  moreCompaniesLookupSelectedDataItem = new EventEmitter();

  constructor(
    private domSanitization: DomSanitization,
    private service: UserService,
    private toastr: ToastrService,
    public commonFunctions: CommonFunctions) {
    this.setKendoGridDefaultValues();
  }

  ngOnInit() {
    this.loadData();
  }

  private setKendoGridDefaultValues(): void {
    this.pageNumber = KendoModalGridProperties.pageNumber;
    this.skip = KendoModalGridProperties.skip;
    this.loading = KendoModalGridProperties.loading;
    this.sort = [{ field: undefined, dir: undefined }];
    this.filter = { filters: [], logic: 'and' };
    this.sortColumn = undefined;
    this.sortDirection = undefined;
    this.filterColumn = undefined;
    this.filterValue = undefined;
  }

  private loadData(): void {
    this.loading = true;
    this.selectedItemId = [];
    this.emptyKendoGrid();
    this.service.getOtherAccessedCompanies(
      this.sortColumn,
      this.sortDirection,
      this.filterColumn,
      this.filterValue,
      this.pageSize,
      this.pageNumber,
      this.existingCompanies
    ).subscribe({
      next: (result: GridResponseBaseOfCompanyModel) => {
        this.loading = false;
        if (result) {
          result = this.domSanitization.sanitizeListModel(result);
          this.moreCompaniesGridView = {
            data: result.items,
            total: result.totalRecordCount
          };
        }
      },
      error: error => {
        this.loading = false;
        this.commonFunctions.handleErrorResponse(error, this.toastr);
      }
    });
  }

  private emptyKendoGrid(): void {
    this.moreCompaniesGridView = {
      data: [],
      total: 0
    };
  }

  public sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    this.sortColumn = sort[0].dir !== undefined ? sort[0].field : undefined;
    this.sortDirection = sort[0].dir;
    this.loadData();
  }

  public pageChange(event: PageChangeEvent): void {
    this.pageNumber = KendoMasterGridProperties.getPageNumber(event.skip, event.take);
    this.skip = event.skip;
    this.pageSize = event.take;
    this.loadData();
  }

  public filterChange(filter: CompositeFilterDescriptor): void {
    if (filter.filters.length > 1) {
      this.toastr.error(ValidationErrorExpressionConstants.kendoGridFilterOnlybyOneColumnErrorMessage);
      this.filter = <CompositeFilterDescriptor>{ filters: [this.filter.filters[0]], logic: 'and' };
    } else {
      this.filter = filter;
      this.clearRequestFields();
      if (this.filter.filters.length === 1) {
        const filterColumnNameColumnValue = KendoMasterGridProperties.getFilterColumnNameColumnValue(this.filter);
        this.filterColumn = filterColumnNameColumnValue.columnName;
        this.filterValue = filterColumnNameColumnValue.columnValue;
      } else {
        this.filterColumn = undefined;
        this.filterValue = undefined;
      }
      this.loadData();
    }
  }
  private clearRequestFields(): void {
    this.pageSize = KendoModalGridProperties.pageSize;
    this.pageNumber = KendoModalGridProperties.pageNumber;
    this.skip = KendoModalGridProperties.skip;
    this.loading = KendoModalGridProperties.loading;
    this.sortColumn = undefined;
    this.sortDirection = undefined;
    this.filterColumn = undefined;
    this.filterValue = undefined;
  }

  onLookupSelect(value: boolean) {
    if (value) {
      const company = (<Array<CompanyModel>>this.moreCompaniesGridView.data).find(x => x.companyId === this.selectedItemId[0]);
      const convertedCompany = new ICompany();
      convertedCompany.CompanyId = company.companyId;
      convertedCompany.CompanyName = company.companyName;
      this.moreCompaniesLookupSelectedDataItem.emit({ selected: true, item: convertedCompany });
    } else {
      this.moreCompaniesLookupSelectedDataItem.emit({ selected: false });
    }
  }
}
