<div class="header-bar-wrap" *ngIf="UserName">
    <nav class="navbar navbar-expand-lg">
        <div class="logo-wrap">
            <a data-test-id="input-link-mh-home" href="/"><img
                    src="assets/images/logo.svg"
                    alt="logo"
                ></a>
            <button
                type="button"
                id="sidebarCollapse"
                (click)="sidebarClick()"
                [class.active]="sidebarCollapseClick"
                class="sidebar-collapse"
            >
                <i class="collapse-left"></i>
                <i class="expand-list"></i>
            </button>
        </div>
    </nav>
    <div
        data-test-id="input-div-mh-env"
        [class]="['header-bar', 'env-' + environment]"
        (click)="debugChangeEnvironmentStyle($event)"
    >

        <a
            data-test-id="input-link-mh-lgout"
            (click)="Logout()"
            class="logout-text"
        >LOGOUT</a>
        <span title="{{UserName}}">{{UserName}}</span>
        <div class="company-dropdown-wrap dropdown">
            <div
                class="dropdown-toggle"
                id="dropdownMenu2"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
            >
                <div class="companyCode-wrap">
                    <span class="a-comapny-code">{{companyCode}} </span>
                </div>
                <div class="collapse-icon-wrap">
                    <i class="collapse-icon"></i>
                </div>
            </div>
            <ul
                class="dropdown-menu"
                aria-labelledby="dropdownMenu2"
            >
                <li
                    class="dropdown-item"
                    *ngFor="let item of companyList"
                    (click)="companyChange(item.CompanyName)"
                    id="input-link-mh-{{item.CompanyName}}"
                >{{item.CompanyName}}</li>
                <li
                    data-test-id="input-link-mh-mrcmp"
                    class="dropdown-item more-companies"
                    (click)="moreCompaniesClick('open')"
                >MORE COMPANIES</li>
            </ul>
        </div>
        <span
            *ngIf="showNotifications"
            data-test-id="input-link-mh-ntf"
            class="mail-notification"
            (click)="goToNotificationsmessages()"
        >
            <span *ngIf="notReadNotifications !== 0">{{notReadNotifications}}</span>
        </span>
        <div
            kendoTooltip
            #tooltip="kendoTooltip"
            *ngIf="environment !== 'production'"
            position="left"
            tooltipClass="dark"
        >
            <span
                class="env-badge"
                title="This is not a production environment"
                (click)="tooltip.show(tooltip)"
            >{{environment}}</span>
        </div>
    </div>
</div>
<morecompanies-lookup
    *ngIf="moreCompaniesModelOpen"
    [existingCompanies]='existingCompanies'
    (moreCompaniesLookupSelectedDataItem)='moreCompaniesModelEvent($event)'
></morecompanies-lookup>
<common-loading-indicator [show]="commonFunctions.getLoadingStatus(loading)"></common-loading-indicator>
