// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --configuration=migration` replaces `environment.ts` with `environment.migration.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  envName: 'migration',
  production: true,
  apiUrl: 'https://apis-migration.cdcnapps.com/u2/api/',
  apiHost: 'https://apis-migration.cdcnapps.com/u2',
  tenant: 'consumerdirectcare.onmicrosoft.com',
  clientId: '649cb394-e17e-4d96-98fc-a180b75287e6',
  crmCloudModelAppId: '02dfe836-2e25-ee11-a814-6045bd0a0b7d',
  crmCloudURLParameter1: '.crm.dynamics.com/main.aspx?appid=',
  crmCloudURLParameter2: '&pagetype=entityrecord&etn=contact&id=',
  redirectUri: window.location.origin,
  authority: 'https://login.microsoftonline.com/',
  appIdentifier:'f8562977-3006-47c6-8420-037183e30dcf',
  apiScope: 'https://consumerdirectcare.onmicrosoft.com/49f953a3-f8aa-4192-930e-2e7985cbd71e/API.Access',
  endPoints: {
  },
  navigateToLoginRequestUrl: false,
  cacheLocation: 'localStorage',
  appInsights: {
    instrumentationKey: '4c86bbd2-d97a-48fc-84d0-214fc381684a'
  },
  notificationApiHost: 'https://apis-migration.cdcnapps.com/notification',
  billingApiHost: 'https://apis-migration.cdcnapps.com/billing',
  payrollApiHost: 'https://apis-migration.cdcnapps.com/payroll',
  peopleApiHost: 'https://apis-migration.cdcnapps.com/people',
  rolesApiHost: 'https://apis-migration.cdcnapps.com/roles',
  usersApiHost: 'https://apis-migration.cdcnapps.com/users',
  authorizationApiHost: 'https://apis-migration.cdcnapps.com/authorization',
  configurationApiHost: 'https://apis-migration.cdcnapps.com/configuration',
  falloutApiHost: 'https://apis-migration.cdcnapps.com/fallout',
  noteApiHost: 'https://apis-migration.cdcnapps.com/note',
  utexApiHost: 'https://apis-migration.cdcnapps.com/utex',
  V4PortalUrl: 'https://v4-portal-migration.cdcnapps.com/#',
  intframeworkApiHost: 'https://apis-migration.cdcnapps.com/intframework',
  crmCloudDomain: 'https://crmdryrun',
  renderingProviderNumberNotRequired: new Array('CDFL', 'CDTN'),
  logging: {
    enableTrace:false
  }
};

/*
 * In development mode, for easier debugging, you can ignore zone related error
 * stack frames such as `zone.run`/`zoneDelegate.invokeTask` by importing the
 * below file. Don't forget to comment it out in production mode
 * because it will have a performance impact when errors are thrown
 */
  // import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
